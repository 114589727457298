export const DataEN = {
  navbar: [
    {
      href: "/#",
      title: "Home",
    },
    {
      href: "/#about",
      title: "About Us",
    },
    {
      href: "/#services",
      title: "Services",
    },
    {
      href: "/#order",
      title: "Order",
    },
  ],
  hero: {
    title1: "Turning your",
    title2: "ideas into reality",
    subtitle:
      "Experience the finest textiles with us. Our fabrics are made with the highest quality materials and are crafted with care and precision.",
    btn1: "Leave a request",
    btn2: "Learn more",
  },
  sectionNames: {
    about: "About Us",
    services: "Our Services",
    order: "How to order?",
    partners: "Trusted by",
    fasonak: "Fasonak",
    FAQ: "Frequently Asked Questions",
    seeAllBtn: "see all",
    seeLessBtn: "see less",
  },
  about: {
    heading:
      "We are a textile factory located in Dushanbe, Tajikistan, offering a wide range of high-quality sewing services.",
    paragraphs: [
      "Our factory was established in 2020, and our opening ceremony was honored by the presence of the President of Tajikistan.",
      "Currently, we have a team of 140+ skilled employees, who are dedicated to delivering the best possible products to our clients.",
      "We are committed to continuous growth and improvement, and we have been able to double our production with each passing year.",
      "Our mission is to provide our clients with exceptional products and services, while also contributing to the economic development of our region.",
    ],
    btn: "Let's talk",
  },

  serviceCards: [
    {
      heading: "Clothing Design",
      description:
        "Our experienced team of designers work closely with you to develop unique and innovative designs that meet your specific needs. We are committed to delivering exceptional designs that are both practical and stylish.",
    },
    {
      heading: "Pattern tailoring and development",
      description:
        "Our skilled pattern makers use modern equipment and techniques to create custom patterns that fit your exact measurements. We are committed to delivering a perfect fit that enhances your comfort and confidence.",
    },
    {
      heading: "Materials and fittings selection",
      description:
        "We carefully select the finest materials and fittings to ensure that our products are of the highest quality. Our team of experts will work with you to select the materials and fittings that meet your specific needs.",
    },
    {
      heading: "Wholesale tailoring",
      description:
        "We offer wholesale tailoring services for a variety of products. Our team of experts can help you streamline your production process and ensure that your products meet your high standards of quality.",
    },
    {
      heading: "Uniforms and overalls tailoring",
      description:
        "Our tailors specialize in the tailoring of uniforms and overalls. We can create custom designs that meet your specific needs and are both practical and stylish.",
    },
    {
      heading: "Silkscreen transfer printing",
      description:
        "Silkscreen transfer printing technology allows us to create stunning designs that are both durable and long-lasting. We can help you create custom designs that make a statement and enhance your brand.",
    },
    {
      heading: "Packaging and labeling finished products",
      description:
        "We take great care to ensure that our products are packaged and labeled to the highest standards. Our team of experts will ensure that your products are packaged and labeled in a way that enhances their quality and appeal.",
    },
    {
      heading: "Product logistics",
      description:
        "We offer comprehensive product logistics services to ensure that your products are delivered on time and in perfect condition. Our logistics experts will work with you to develop a customized logistics plan that meets your specific needs.",
    },
  ],
  orderCards: [
    {
      heading: "Specialist Consultation",
      description:
        "During our initial consultation, we will work closely with you to determine the best approach to your project. We are committed to deliver a custom solution that meets your needs.",
    },
    {
      heading: "Design Development",
      description:
        "Our experienced designers and pattern makers work together to develop a custom design that meets your requirements. We create a sample for your approval before starting production.",
    },
    {
      heading: "Signing Contract",
      description:
        "Our team will provide you with a detailed cost and timeline estimate before beginning production. Once agreed, we will prepare a contract and request advance payment.",
    },
    {
      heading: "Order Production",
      description:
        "We use innovative equipment and techniques to ensure consistent, high-quality production. We are committed to meeting your deadline and delivering a flawless finished product.",
    },
    {
      heading: "Quality Control",
      description:
        "Our quality control process ensures that each product meets our high standards of quality. After inspection, your products are carefully packaged and labeled for shipping.",
    },
    {
      heading: "Shipment",
      description:
        "Once your order is complete, we will contact you for final payment and arrange for the shipment of your goods. Sit back and relax, knowing your products are on their way to you.",
    },
  ],
  subscribe: {
    heading: "Subscribe to our newsletter now!",
    subheading:
      "Be the first to know about our new products, services, and offers.",
    placeholder: "Enter your email",
    btn: "Submit",
  },

  faq: [
    {
      question: "What are your minimum order quantities for textile products?",
      answer:
        "Our minimum order quantity for most of our products is 100 units, please contact us for more information on specific products",
    },
    {
      question: "Where can we see your products?",
      answer:
        "Please contact us and we will send you all the needed information, including product catalogs and samples. We are happy to work with you to find the right textile products for your needs.",
    },
    {
      question: "Where is your textile factory located?",
      answer:
        "Our textile factory is located in Dushanbe, Tajikistan, near Farovon mall. If you would like to visit our factory or have any questions about our location, please don't hesitate to contact us.",
    },
    {
      question: "What types of textiles do you produce?",
      answer:
        "We are a full cycle textile factory and produce a wide range of textiles, from t-shirts to coats and everything in between. Our production capabilities include various natural and synthetic fibers and we have the ability to create custom fabrics based on your specifications.",
    },
    {
      question: "Do you provide shipping and logistics services?",
      answer: "Yes, we provide shipping and logistics services.",
    },
    {
      question: "Can you produce custom textiles based on our specifications?",
      answer:
        "Yes, we can produce custom textiles based on your specifications, please contact us for more information.",
    },
  ],

  contact: {
    title: "Get In Touch",
    subtitle: "We are here for you! How can we help you?",
    namePlaceholder: "Enter your name",
    emailPlaceholder: "Enter your email",
    messagePlaceholder: "Leave your message",
    address: "2/1 Chorteppa st. Dushanbe, Tajikistan",
    btn: "Submit",
  },

  footer: {
    text: "Thank you for getting in touch with us. We'll get back to you as soon as possible.",
    number: "+992 (00) 055 44 33",
    email: "info@tojtex.tj",
    menu: [
      {
        title: "Navigation",
        items: [
          {
            href: "/#",
            title: "Home",
          },
          {
            href: "/#about",
            title: "About Us",
          },
          {
            href: "/#services",
            title: "Services",
          },
          {
            href: "/contact",
            title: "Contacts",
          },
        ],
      },

      {
        title: "Support",
        items: [
          {
            href: "/contact",
            title: "Contacts",
          },
          {
            href: "/#order",
            title: "Order",
          },
          {
            href: "/faq",
            title: "FAQ",
          },
        ],
      },
    ],
  },

  toast: {
    contact: "Thank you for your message!",
    subscribe: "Thank you for joining us!",
  },
};
