import React from "react";
import About from "./About/About";
import Hero from "./Hero/Hero";
import Order from "./Order/Order";
import Partners from "./Partners/Partners";
import Services from "./Services/Services";
import Subscribe from "./Subscribe/Subscribe";

interface HomeProps {
  t: Function;
}

const Home: React.FC<HomeProps> = ({ t }) => {
  return (
    <>
      <Hero t={t} />
      <About t={t} />
      <Services t={t} />
      <Order t={t} />
      <Partners t={t} />
      <Subscribe t={t} />
    </>
  );
};
export default Home;
