import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import Navigation from "../Navigation/Navigation";
const logo = require("../../img/logo.png") as string;

interface NavbarProps {
  t: Function;
  changeLanguage: Function;
  i18n: any;
}
const Navbar: React.FC<NavbarProps> = ({ t, changeLanguage, i18n }) => {
  const navbarItems = t("navbar", { returnObjects: true });

  const hamburger = document!.querySelector(
    ".hamburger"
  ) as HTMLInputElement | null;
  document.body.style.overflow = "hidden";

  const navigation = document!.querySelector(
    ".navigation"
  ) as HTMLInputElement | null;

  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(i18n.language.slice(0, 2).toUpperCase());
  }, [i18n.language]);

  function changeLng() {
    if (i18n.language === "ru") {
      changeLanguage("en");
    } else {
      changeLanguage("ru");
    }
  }

  const onClickHamburger = () => {
    hamburger?.classList.toggle("is-active");
    navigation?.classList.toggle("is-active");
    //Toggle Active
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="navbar__container">
          <div className="navbar__half">
            <HashLink to={"/#"}>
              <img src={logo} alt="tojtex-logo" className="navbar__logo" />
            </HashLink>
            <div className="navbar__menu">
              {navbarItems.map((item: any, key: number) => (
                <HashLink
                  to={item.href}
                  className="navbar__menu--link"
                  key={key}
                >
                  {item.title}
                </HashLink>
              ))}
            </div>
          </div>
          <div className="navbar__half">
            <button className="navbar__language" onClick={changeLng}>
              {language}
            </button>
            <div className="navbar__hamburger">
              <button
                className="hamburger hamburger--collapse"
                type="button"
                onClick={() => onClickHamburger()}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <Navigation
          onClickHamburger={onClickHamburger}
          navbarItems={navbarItems}
          currentLanguage={language}
          changeLng={changeLng}
        />
      </nav>
    </header>
  );
};

export default Navbar;
