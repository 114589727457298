import React, { useState } from "react";

interface ServiceCardProps {
  id: number;
  heading: string;
  description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  id,
  heading,
  description,
}) => {
  return (
    <div className="card">
      <div className="card__overlay" id={"card-".concat((id + 1).toString())} />
      <div className="card__container">
        <div className="card__heading">
          <h2 className="card__heading--title"> {heading}</h2>
          <i className="bx bxs-circle"></i>
        </div>
        <p className="card__content">{description}</p>
      </div>
    </div>
  );
};
export default ServiceCard;
