export const DataRU = {
  navbar: [
    {
      href: "/#",
      title: "Главная",
    },
    {
      href: "/#about",
      title: "О Нас",
    },
    {
      href: "/#services",
      title: "Услуги",
    },
    {
      href: "/#order",
      title: "Заказ",
    },
  ],
  hero: {
    title1: "Воплотим Вашу",
    title2: "идею в реальность",
    subtitle:
      "Мы выполняем полный производственный цикл по пошиву одежды. На сайте представлена вся информация об условиях сотрудничества с производством.",
    btn1: "Оставить заявку",
    btn2: "Узнать больше",
  },
  sectionNames: {
    about: "О Нас",
    services: "Наши услуги",
    order: "Как оформить заказ?",
    partners: "Нам доверяют",
    fasonak: "Fasonak",
    FAQ: "Вопросы",
    seeAllBtn: "еще",
    seeLessBtn: "скрыть",
  },
  about: {
    heading:
      "Точтекс - это текстильная фабрика, расположенная в Душанбе, Таджикистане. Мы предлагаем широкий спектр высококачественных швейных услуг.",
    paragraphs: [
      "10 июля 2022 года состоялось официальное открытие фабрики Точтекс при участии Президента Таджикистана Эмомали Рахмона.",
      "В настоящее время наша команда состоит из более чем 140 квалифицированных сотрудников, которые помогают доставлять наилучшие продукты нашим клиентам.",
      "Мы стремимся к непрерывному росту и улучшению, и с каждым годом удваиваем нашу производственную мощность.",
      "Наша миссия - предоставлять нашим клиентам исключительные продукты и услуги, а также вносить свой вклад в экономическое развитие нашей страны",
    ],
    btn: "Связаться",
  },

  serviceCards: [
    {
      heading: "Дизайн одежды",
      description:
        "Наш опытный дизайнерский коллектив cвяжется с вами для разработки уникальных дизайнов, отвечающих вашим требованиям.",
    },
    {
      heading: "Разработка лекал и пошив одежды",
      description:
        "Наши модельеры используют современное оборудование и технологии для создания индивидуальных лекал, которые соответствуют точным измерениям.",
    },
    {
      heading: "Подбор материалов и фурнитуры",
      description:
        "Мы тщательно выбираем лучшие материалы и фурнитуру, чтобы гарантировать качество. Наша команда экспертов будет работать с вами для выбора материалов.",
    },
    {
      heading: "Оптовый пошив одежды",
      description:
        "Мы предлагаем услуги по оптовому пошиву различных изделий. Специалисты нашей команды смогут помочь вам оптимизировать производственный процесс.",
    },
    {
      heading: "Производство под ключ",
      description:
        "Возьмем на себя весь цикл производства вашей коллекции от разработки лекал до отгрузки готовой продукции.",
    },
    {
      heading: "Шелкография и трансферная печать",
      description:
        "Технология термотрансфера позволяет нам создавать потрясающие дизайны, которые являются долговечными и прочными.",
    },
    {
      heading: "ОТК и упаковка / маркировка",
      description:
        "Каждое изделие проходит пять этапов контроля качества. У нас вы можете заказать услугу по упаковке и маркировке продукции для маркетплейсов или магазинов.",
    },
    {
      heading: "Логистика",
      description:
        "Мы предлагаем комплексные услуги логистики продукции. Наши эксперты по логистике будут работать с вами, чтобы разработать индивидуальный план логистики.",
    },
  ],

  orderCards: [
    {
      heading: "Заявка и консультация",
      description:
        "На первой встрече мы узнаем все ваши пожелания, расскажем о всех производственных процессах на швейной фабрике, проконсультируем вас по срокам и примерной стоимости изделий.",
    },
    {
      heading: "Разработка конструкции",
      description:
        "Наши опытные дизайнеры и модельеры работают вместе, чтобы разработать индивидуальный дизайн и лекала. Мы создадим образец для вашего утверждения, прежде, чем начать производство.",
    },
    {
      heading: "Заключение договора",
      description:
        "Наша команда предоставит вам подробную оценку стоимости и сроков до начала производства. После согласования мы подготовим договор и запросим авансовый платеж.",
    },
    {
      heading: "Производство партии",
      description:
        "Наши швеи работают по принципу конвеера, поэтому нам удается аккуратно и вовремя отшивать большое количество изделий",
    },
    {
      heading: "Контроль качества",
      description:
        "Процесс контроля качества гарантирует, чтобы каждый продукт соответствовал высоким стандартам качества. После проверки продукты упаковываются и маркируются для отправки.",
    },
    {
      heading: "Отгрузка товара",
      description:
        "Как только ваш заказ будет выполнен, мы свяжемся с вами для оплаты и организации отправки товаров.",
    },
  ],
  subscribe: {
    heading: "Подпишитесь на нашу рассылку!",
    subheading:
      "Будьте первыми, кто узнает о наших новых продуктах, услугах и предложениях.",
    placeholder: "Введите ваш email",
    btn: "Подписаться",
  },
  faq: [
    {
      question: "Какой минимальный заказ для текстильной продукции?",
      answer:
        "Минимальный заказ для большинства наших продуктов составляет 100 единиц, пожалуйста, свяжитесь с нами для получения более подробной информации о конкретных продуктах.",
    },
    {
      question: "Где можно увидеть ваши продукты?",
      answer:
        "Пожалуйста, свяжитесь с нами, и мы отправим вам всю необходимую информацию, включая каталоги и образцы продукции. Мы с радостью поможем вам подобрать подходящие текстильные продукты для ваших нужд.",
    },
    {
      question: "Где находится ваша текстильная фабрика?",
      answer:
        "Наша текстильная фабрика находится в Душанбе, Таджикистан, недалеко от ТЦ Фаровон. Если вы хотите посетить нашу фабрику или у вас есть вопросы о нашем местоположении, не стесняйтесь связаться с нами.",
    },
    {
      question: "Какие изделия вы производите?",
      answer:
        "Мы полный цикл текстильной фабрики и производим широкий спектр текстилей, от футболок до пальто и многого другого. Наши производственные возможности включают различные натуральные и синтетические волокна, и мы можем создавать ткани по вашим спецификациям.",
    },
    {
      question: "Вы предоставляете услуги по доставке и логистике?",
      answer: "Да, мы предоставляем услуги по доставке и логистике.",
    },
    {
      question:
        "Вы можете производить индивидуальные текстильные изделия на основе наших требований?",
      answer:
        "Да, мы можем производить индивидуальные текстильные изделия на основе ваших требований. Пожалуйста, свяжитесь с нами для получения более подробной информации.",
    },
  ],

  contact: {
    title: "Свяжитесь с нами",
    subtitle: "Мы всегда на связи. Готовы ответить на ваши вопросы.",
    namePlaceholder: "Введите ваше имя",
    emailPlaceholder: "Введите вашу почту",
    messagePlaceholder: "Оставьте сообщение",
    address: "г.Душанбе, Таджикистан, ул.Чортеппа, д. 2/1",
    btn: "Отправить",
  },

  footer: {
    text: "Благодарим за обращение. Мы ответим на ваше сообщение в кратчайшие сроки.",
    number: "+992 (00) 055 44 33",
    email: "info@tojtex.tj",
    menu: [
      {
        title: "Навигация",
        items: [
          {
            href: "/#",
            title: "Главная",
          },
          {
            href: "/#about",
            title: "О Нас",
          },
          {
            href: "/#services",
            title: "Услуги",
          },
          {
            href: "/contact",
            title: "Контакты",
          },
        ],
      },

      {
        title: "Поддержка",
        items: [
          {
            href: "/contact",
            title: "Контакты",
          },
          {
            href: "/#order",
            title: "Заказ",
          },
          {
            href: "/faq",
            title: "Вопросы",
          },
        ],
      },
    ],
  },

  toast: {
    contact: "Спасибо за Ваше сообщение!",
    subscribe: "Добро пожаловать в наш клуб!",
  },
};
