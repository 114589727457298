import { HashLink } from "react-router-hash-link";
const hero = require("../../img/hero.jpg") as string;
interface HeroProps {
  t: Function;
}

const Hero: React.FC<HeroProps> = ({ t }) => {
  return (
    <div className="hero">
      <div className="hero__half">
        <h1 className="hero__title">
          {t("hero.title1")} <br />
          {t("hero.title2")}
        </h1>
        <h2 className="hero__subtitle">{t("hero.subtitle")}</h2>
        <div className="hero__buttons">
          <HashLink to="/contact" className="btn btn--request">
            {t("hero.btn1")}
          </HashLink>
          <HashLink to={"#about"} className="btn btn--more">
            {t("hero.btn2")}
          </HashLink>
        </div>
      </div>
      <div className="hero__half">
        <img alt="tojtex" src={hero}></img>
      </div>
    </div>
  );
};
export default Hero;
