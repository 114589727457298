import React from "react";
import { Routes, Route } from "react-router-dom";
import Contact from "../components/Contact/Contact";
import FAQ from "../components/FAQ/FAQ";
import Home from "../components/Home";

interface MainProps {
  t: Function;
}

const Main: React.FC<MainProps> = ({ t }) => {
  return (
    <Routes>
      <Route path="/" element={<Home t={t} />} />
      <Route path="/faq" element={<FAQ t={t} />} />
      <Route path="/contact" element={<Contact t={t} />} />
    </Routes>
  );
};
export default Main;
