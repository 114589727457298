interface QuestionProps {
  question: string;
  answer: string;
}

const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  return (
    <div className="faq__question">
      <div className="faq__heading">
        <span>{question}</span>
      </div>
      <p className="faq__paragraph">{answer}</p>
    </div>
  );
};
export default Question;
