import { HashLink } from "react-router-hash-link";

const logo = require("../../img/logo.png") as string;

interface FooterProps {
  t: Function;
}

const socialLinks = [
  {
    link: "https://www.facebook.com/tojtex/",
    icon: "bx bxl-facebook-circle",
  },
  {
    link: "https://wa.me/992000554433",
    icon: "bx bxl-whatsapp",
  },
  {
    link: "https://www.instagram.com/tojtex.tj/",
    icon: "bx bxl-instagram",
  },
  {
    link: "mailto:kosimi.a@tojtex.tj",
    icon: "bx bx-envelope",
  },
];

const Footer: React.FC<FooterProps> = ({ t }) => {
  const onClick = (url: string) => {
    window.open(url, "_blank");
  };

  const menu = t("footer.menu", { returnObjects: true });
  return (
    <footer className="footer">
      <div className="footer__container">
        <HashLink to={"/#"} className="footer__logo">
          <img alt="tojtex-logo" src={logo}></img>
        </HashLink>

        <p className="footer__paragraph">
          {t("footer.text")}
          <span>
            {t("footer.number")} <br />
            {t("footer.email")}
          </span>
          <span>
            {t("contact.address")} <br />
          </span>
        </p>
        <div className="footer__social">
          {socialLinks.map((item: any, key: number) => (
            <span key={key} onClick={() => onClick(item.link)}>
              <i className={item.icon}></i>
            </span>
          ))}
        </div>
      </div>
      {menu.map((item: any, key: number) => (
        <div className="footer__container" key={key}>
          <h1 className="footer__title">{item.title}</h1>
          <div className="footer__list">
            {item.items.map((item: any, key: number) => (
              <HashLink to={item.href} key={key} className="footer__list--item">
                {item.title}
              </HashLink>
            ))}
          </div>
        </div>
      ))}
    </footer>
  );
};
export default Footer;
