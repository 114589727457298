import { HashLink } from "react-router-hash-link";

const photo = require("../../img/about.jpg") as string;

interface AboutProps {
  t: Function;
}
const About: React.FC<AboutProps> = ({ t }) => {
  const paragraphs = t("about.paragraphs", { returnObjects: true });

  return (
    <section className="section" id="about">
      <div className="section__heading">
        <div className="section__heading--line"></div>
        <h1 className="section__heading--title">{t("sectionNames.about")}</h1>
        <div className="section__heading--line"></div>
      </div>
      <div className="about">
        <div className="about__half">
          <h3 className="about__subheading">{t("about.heading")}</h3>
          {paragraphs.map((item: any, key: number) => (
            <p className="about__paragraph" key={key}>
              {item}
            </p>
          ))}
        </div>
        <div className="about__half">
          <img alt="tojtex-about" src={photo} className="about__photo" />
        </div>
      </div>
      <HashLink to={"/contact"} className="btn btn--contact">
        {t("about.btn")}
      </HashLink>
    </section>
  );
};
export default About;
