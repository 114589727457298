import React, { Suspense, useEffect } from "react";
import SuspenseFallback from "./components/SuspenseFallback/SuspenseFallback";
import { useTranslation } from "react-i18next";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Main from "./main/Main";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  function changeLanguage(language: string) {
    i18n.changeLanguage(language);
  }

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Navbar t={t} i18n={i18n} changeLanguage={changeLanguage} />
      <Main t={t} />
      <Footer t={t} />
    </Suspense>
  );
};

export default App;
