import React from "react";
import { db } from "../../firebase/firebaseConfig";
import { ref, set } from "firebase/database";
import { toast, ToastContainer } from "react-toastify";

interface SubscribeProps {
  t: Function;
}

const Subscribe: React.FC<SubscribeProps> = ({ t }) => {
  const sendData = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;
    set(ref(db, "subscribers/" + email.substring(0, email.indexOf("@"))), {
      email: email,
    });
    toast.success(t("toast.subscribe"));
  };

  return (
    <section className="section">
      <div className="subscribe">
        <div className="subscribe__half">
          <h3 className="subscribe__heading">{t("subscribe.heading")}</h3>
          <h3 className="subscribe__subheading">{t("subscribe.subheading")}</h3>
        </div>
        <form className="subscribe__half" onSubmit={sendData}>
          <input
            id="email"
            type="email"
            name="email"
            spellCheck="false"
            required={true}
            placeholder={t("subscribe.placeholder")}
            className="subscribe__input"
          />
          <button type="submit" className="btn btn--subscribe">
            {t("subscribe.btn")}
          </button>
        </form>
      </div>
      <ToastContainer toastClassName="toast" />
    </section>
  );
};
export default Subscribe;
