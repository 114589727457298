import React, { useEffect, useState, useRef } from "react";

interface SlideShowProps {
  delay: number;
  translate: number;
  images: Array<string>;
}

const SlideShow: React.FC<SlideShowProps> = ({ delay, translate, images }) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(0);

  function resetTimeout() {
    if (timeoutRef.current !== 1) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = window.setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index, delay, images.length]);

  return (
    <>
      <div
        className="partners__slideshow"
        style={{ transform: `translate3d(${-index * translate}%, 0, 0)` }}
      >
        {images.map((image, key) => (
          <img
            className="partners__slide"
            key={key}
            alt="partner"
            src={image}
          />
        ))}
      </div>
    </>
  );
};

export default SlideShow;
