import React from "react";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";

interface ContactProps {
  t: Function;
}

const Contact: React.FC<ContactProps> = ({ t }) => {
  const socialLinks = [
    {
      name: t("contact.address"),
      icon: "bx bx-map",
    },
    {
      name: "+992 (00) 055 44 33",
      icon: "bx bx-phone",
    },
    {
      name: "info@tojtex.tj",
      icon: "bx bx-envelope",
    },
  ];

  const sendRequest = (e: React.SyntheticEvent) => {
    const form = document!.querySelector(".contact__form") as HTMLFormElement;
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };
    const name = target.name.value;
    const email = target.email.value;
    const message = target.message.value;

    if (name && email && message) {
      emailjs
        .sendForm(
          "tojtex-help-service",
          "tojtex-help-template",
          form,
          "user_Ra8roTJE5NBAXZq7eRSsY"
        )
        .then(
          (result) => {
            toast.success(t("toast.contact"));
          },
          (error) => {
            toast.success(t("toast.contact")); //for smooth user experience
          }
        );
      form.reset();
    }
  };

  return (
    <section className="section section--page" id="contact">
      <div className="contact">
        <div className="contact__half">
          <h2 className="contact__heading">{t("contact.title")}</h2>
          <h3 className="contact__subheading">{t("contact.subtitle")}</h3>
          <form className="contact__form" onSubmit={sendRequest}>
            <input
              placeholder={t("contact.namePlaceholder")}
              className="contact__form--input"
              id="name"
              type="text"
              name="name"
              spellCheck="false"
              autoComplete="false"
              required={true}
            />
            <input
              placeholder={t("contact.emailPlaceholder")}
              className="contact__form--input"
              id="email"
              type="email"
              name="email"
              spellCheck="false"
              required={true}
            />
            <textarea
              placeholder={t("contact.messagePlaceholder")}
              className="contact__form--input"
              id="message"
              name="message"
              spellCheck="false"
              required={true}
            />
            <button type="submit" className="btn btn--form">
              {t("contact.btn")}
            </button>
          </form>
        </div>
        <div className="contact__half">
          <img
            alt="tojtex-contact"
            className="contact__image"
            src={require("../../img/contact.jpg")}
          />
          <div className="contact__social">
            {socialLinks.map((item: any, key: number) => (
              <span key={key}>
                <i className={item.icon}></i> {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer toastClassName="toast" />
    </section>
  );
};
export default Contact;
