import { HashLink } from "react-router-hash-link";
import OrderBox from "./OrderBox";
const images = [
  require("../../img/order/consultation.png"),
  require("../../img/order/design.png"),
  require("../../img/order/contract.png"),
  require("../../img/order/production.png"),
  require("../../img/order/qa.png"),
  require("../../img/order/shipment.png"),
];

interface ServiceProps {
  t: Function;
}

const Order: React.FC<ServiceProps> = ({ t }) => {
  const orderSteps = t("orderCards", { returnObjects: true });

  return (
    <section className="section" id="order">
      <div className="section__heading">
        <div className="section__heading--line"></div>
        <h1 className="section__heading--title">{t("sectionNames.order")}</h1>
        <div className="section__heading--line"></div>
      </div>

      <div className="order">
        {orderSteps.map((step: any, key: number) => (
          <OrderBox
            key={key}
            id={key}
            heading={step.heading}
            description={step.description}
          />
        ))}
      </div>
      <HashLink to={"/contact"} className="btn btn--contact">
        {t("hero.btn1")}
      </HashLink>
    </section>
  );
};
export default Order;
