import React, { useState } from "react";
import ServiceCard from "./ServiceCard";

interface ServiceProps {
  t: Function;
}
const Services: React.FC<ServiceProps> = ({ t }) => {
  const [showMore, setShowMore] = useState(false);
  const cards = t("serviceCards", { returnObjects: true });

  const onClick = () => {
    showMore ? setShowMore(false) : setShowMore(true);
    const slideshow = document!.querySelector(
      ".services__slideshow"
    ) as HTMLInputElement | null;
    slideshow?.classList.toggle("is-visible");
  };

  return (
    <section className="section" id="services">
      <div className="section__heading">
        <div className="section__heading--line"></div>
        <h1 className="section__heading--title">
          {t("sectionNames.services")}
        </h1>
        <div className="section__heading--line"></div>
      </div>
      <div className="services">
        <div className="services__slideshow">
          {cards.map((item: any, key: number) => (
            <ServiceCard
              key={key}
              id={key}
              heading={item.heading}
              description={item.description}
            />
          ))}
        </div>
        <div className="services__more">
          <div className="services__more--line"></div>
          <div className="services__more--link" onClick={() => onClick()}>
            {showMore
              ? t("sectionNames.seeLessBtn")
              : t("sectionNames.seeAllBtn")}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;
