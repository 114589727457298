import React, { useState } from "react";

const images = [
  require("../../img/order/consultation.png"),
  require("../../img/order/design.png"),
  require("../../img/order/contract.png"),
  require("../../img/order/production.png"),
  require("../../img/order/qa.png"),
  require("../../img/order/shipment.png"),
];

interface OrderBoxProps {
  id: number;
  heading: string;
  description: string;
}

const OrderBox: React.FC<OrderBoxProps> = ({ id, heading, description }) => {
  return (
    <div className="order__box">
      <div className="order__box--top">
        <div className="order__box--number">
          <img alt="tojtex-order" src={images[id]} />
        </div>
        <div className="order__box--number">
          <span>{id + 1}</span>
        </div>
      </div>
      <div className="order__box--bottom">
        <h3 className="order__box--title">{heading}</h3>
        <p className="order__box--paragraph">{description}</p>
      </div>
    </div>
  );
};
export default OrderBox;
