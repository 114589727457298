import SlideShow from "./SlideShow";

const images = [
  require("../../img/partners/paykar.png"),
  require("../../img/partners/unicef.png"),
  require("../../img/partners/cocacola.png"),
  require("../../img/partners/paykar.png"),
  require("../../img/partners/unicef.png"),
  require("../../img/partners/cocacola.png"),
  require("../../img/partners/paykar.png"),
  require("../../img/partners/unicef.png"),
  require("../../img/partners/paykar.png"),
];

interface PartnersProps {
  t: Function;
}

const Partners: React.FC<PartnersProps> = ({ t }) => {
  return (
    <section className="section">
      <div className="section__heading">
        <div className="section__heading--line"></div>
        <h1 className="section__heading--title">
          {t("sectionNames.partners")}
        </h1>
        <div className="section__heading--line"></div>
      </div>
      <div className="partners">
        <SlideShow delay={2000} translate={3} images={images} />
      </div>
    </section>
  );
};
export default Partners;
