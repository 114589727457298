import Question from "./Question";

interface FAQProps {
  t: Function;
}

const FAQ: React.FC<FAQProps> = ({ t }) => {
  const faq = t("faq", { returnObjects: true });

  return (
    <section className="section section--page" id="faq">
      <div className="section__heading">
        <div className="section__heading--line"></div>
        <h1 className="section__heading--title">{t("sectionNames.FAQ")}</h1>
        <div className="section__heading--line"></div>
      </div>
      <div className="faq">
        {faq.map((item: any, key: number) => (
          <Question key={key} question={item.question} answer={item.answer} />
        ))}
      </div>
    </section>
  );
};
export default FAQ;
