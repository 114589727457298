import { HashLink } from "react-router-hash-link";

interface NavigationProps {
  navbarItems: any;
  currentLanguage: string;
  changeLng: () => void;
  onClickHamburger: () => void;
}

const socialLinks = [
  {
    link: "https://www.facebook.com/tojtex/",
    icon: "bx bxl-facebook-circle",
  },
  {
    link: "https://wa.me/992000554433",
    icon: "bx bxl-whatsapp",
  },
  {
    link: "https://www.instagram.com/tojtex.tj/",
    icon: "bx bxl-instagram",
  },
  {
    link: "mailto:kosimi.a@tojtex.tj",
    icon: "bx bx-envelope",
  },
];

const Navigation: React.FC<NavigationProps> = ({
  navbarItems,
  currentLanguage,
  changeLng,
  onClickHamburger,
}) => {
  const onClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="navigation">
      <div className="navigation__language">
        <button className="navigation__language--item" onClick={changeLng}>
          {currentLanguage}
        </button>
      </div>

      {navbarItems.map((item: any, key: number) => (
        <HashLink
          to={item.href}
          className="navigation__link"
          key={key}
          onClick={onClickHamburger}
        >
          {item.title}
        </HashLink>
      ))}

      <div className="navigation__social">
        {socialLinks.map((item: any, key: number) => (
          <span key={key} onClick={() => onClick(item.link)}>
            <i className={item.icon}></i>
          </span>
        ))}
      </div>
    </div>
  );
};
export default Navigation;
